import RoleManager from './RoleManager';
import LoggerFactory from '../log/index';
import { Auth } from '@bloobirds-it/bloobirds-platform-js-api-library';

const logger = LoggerFactory.create({ name: 'SessionManager' });

const SESSION_KEY = 'bb-session';

const setSession = sessionData => {
  localStorage.setItem(SESSION_KEY, JSON.stringify(sessionData));
};
const getSession = () => {
  return JSON.parse(localStorage.getItem(SESSION_KEY) || {});
};
const b64DecodeUnicode = str => {
  return decodeURIComponent(
    Array.prototype.map
      .call(atob(str), c => {
        return `%${`00${c.charCodeAt(0).toString(16)}`.slice(-2)}`;
      })
      .join(''),
  );
};
const parseJwt = token => {
  return JSON.parse(b64DecodeUnicode(token.split('.')[1]));
};
/* set session if empty on startup */
if (localStorage.getItem(SESSION_KEY) === null) {
  setSession({});
}

const SessionManagerFactory = () => {
  const setToken = token => {
    Auth.simpleAuthTokenManagerInstance.setToken(token);
    const decoded = parseJwt(token);
    const session = getSession();
    session.context = {};
    session.context.token = token;
    session.context.decoded = decoded;
    session.context.user = { id: decoded.sub, name: decoded.userName };
    session.context.account = { id: decoded.account, name: decoded.accountName };
    setSession(session);
    logger.info(`New context token configured for user ${decoded.userName}`);
  };
  const getRootToken = () => {
    return getSession().token;
  };
  const restoreToRootToken = () => {
    setToken(getRootToken());
  };
  const setRootToken = token => {
    Auth.simpleAuthTokenManagerInstance.setToken(token);
    const decoded = parseJwt(token);
    const session = getSession();
    session.token = token;
    setSession(session);
    logger.info(`New root token configured for user ${decoded.userName}`);
    restoreToRootToken();
    return true;
  };
  const getUser = () => {
    if (getSession().context) {
      return getSession().context.user;
    }

    return undefined;
  };
  const getToken = () => {
    return getSession().context.token;
  };
  const getRoles = () => {
    const claims = getSession().context.decoded;
    if (claims !== undefined) {
      return claims.userRoles || [];
    }
    return [];
  };
  const getRoleManager = () => {
    return RoleManager(getRoles());
  };
  const setAccount = (accountId, accountName) => {
    const session = getSession();
    session.context.account = { id: accountId, name: accountName };
    setSession(session);
  };
  const getAccount = () => {
    if (getSession().context) {
      return getSession().context.account;
    }
    return undefined;
  };
  const hasToken = () => {
    return (
      getSession() !== undefined &&
      getSession().context !== undefined &&
      getSession().context.token !== null
    );
  };
  const cleanSession = () => {
    Auth.simpleAuthTokenManagerInstance.clean();
    setSession({});
  };
  const isSwitchingToken = () => {
    const session = getSession();
    return session.token !== session.context.token;
  };
  return {
    cleanSession,
    setRootToken,
    getRootToken,
    restoreToRootToken,
    isSwitchingToken,
    hasToken,
    getRoleManager,
    setAccount,
    getAccount,
    getUser,
    setToken,
    getToken,
  };
};

if (SessionManagerFactory().hasToken()) {
  Auth.simpleAuthTokenManagerInstance.setToken(SessionManagerFactory().getToken());
}

export default SessionManagerFactory;
